<template>
    <b-tabs
        pills
    >
        <b-tab
            :title="i18nT(`Finances`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="coins"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Finances`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Finances`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col cols="12">
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col md="4" cols="12">
                            <v-select
                                v-model="year"
                                :options="years"
                                :clearable="false"   
                                label="text"
                                :reduce="(e) => e.value"  
                                @input="getStats"                                  
                            />
                        </b-col>
                        <b-col cols="12">
                            <vue-apex-charts
                                id="properties-income-chart"
                                type="donut"
                                height="600"
                                :series="pieSeries"
                                :options="pieChartOptions"
                                ref="pieChart"
                            />
                        </b-col>
                        <b-col cols="12">
                            <hr class="mt-2 mb-2"/>
                        </b-col>
                        <b-col cols="12" class="my-2">
                            <vue-apex-charts
                                id="properties-income-chart"
                                type="area"
                                height="400"
                                :series="areaSeries"
                                :options="areaChartOptions"
                                ref="areaChart"
                            />
                        </b-col>
                        <b-col
                            :lg="stats.TotalVAT > 0 ? '4' : '6'"
                            md="6"
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <h6 class="font-small-2">{{ i18nT(`Revenu brut`) }} <br> {{ i18nT(`(année)`) }}</h6>
                                    <h3
                                        class="m-0 text-primary"
                                    >
                                        {{ {amount: stats.TotalGrossIncome} | currency }}
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            :lg="stats.TotalVAT > 0 ? '4' : '6'"
                            md="6"
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <h6 class="font-small-2">{{ i18nT(`Dépenses`)}} <br> {{ i18nT(`(année)`) }}</h6>
                                    <h3
                                        class="m-0 text-danger"
                                    >
                                        {{ {amount: stats.TotalExpenses} | currency }}
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            lg="4"
                            md="6"
                            cols="12"
                            v-if="stats.TotalVAT > 0"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <h6 class="font-small-2">{{ i18nT(`TVA encaissée`)}} <br> {{ i18nT(`(année)`) }}</h6>
                                    <h3
                                        class="m-0 text-primary"
                                    >
                                        {{ {amount: stats.TotalVAT} | currency }}
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            :lg="currentBuilding.BuildingInitialPrice > 0 ? '4' : '6'"
                            md="6"
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <div class="d-flex align-items-center mb-1">
                                        <h6 class="mr-50 font-small-2 m-0 pt-25 pb-25">{{ i18nT(`Taux d'occupation`) }} <br> {{ i18nT(`(année)`) }}</h6>
                                    </div>
                                    <h3
                                        class="m-0"
                                    >
                                        {{ +stats.OccupationRate }} %
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            :lg="currentBuilding.BuildingInitialPrice > 0 ? '4' : '6'"
                            md="6"
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <div class="d-flex align-items-center mb-1">
                                        <h6 class="mr-50 font-small-2 m-0 pt-25 pb-25">{{ i18nT(`Rentabilité`) }} <br> {{ i18nT(`(nette)`) }}</h6>
                                        <span v-b-tooltip.hover="i18nT(`La rentabilité locative nette est calculé à la base de la formule suivante: (Loyer annuel – Dépenses) x 100 / Prix d’acquisition du bien`)" >
                                            <RtIcon variant="light mb-1" icon="circle-info"/>
                                        </span>
                                    </div>
                                    <h3
                                        class="m-0"
                                        v-if="currentBuilding.BuildingInitialPrice"
                                    >
                                        {{ stats.Profitability }} %
                                    </h3>
                                    <div class="mb-50 font-small-3" v-if="!currentBuilding.BuildingInitialPrice">
                                        <span class="m-0"
                                        >
                                            {{ i18nT(`Prix d'acquisition inconnu`) }}
                                        </span>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            lg="4"
                            md="6"
                            cols="12"
                            v-if="currentBuilding.BuildingInitialPrice"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1">
                                    <div class="d-flex align-items-center mb-1">
                                        <h6 class="mr-50 font-small-2 m-0 pt-25 pb-25">{{ i18nT(`Rentabilité`) }} <br> {{ i18nT(`(brute)`) }}</h6>
                                        <span v-b-tooltip.hover="i18nT(`La rentabilité locative brute est calculé à la base de la formule suivante: (Loyer annuel) x 100 / Prix d’acquisition du bien`)">
                                            <RtIcon variant="light mb-1" icon="circle-info"/>
                                        </span>
                                    </div>
                                    <h3
                                        class="m-0"
                                    >
                                        {{ stats.ProfitabilityGross }} %
                                    </h3>
                                </b-card-body>
                            </b-card>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <b-card no-body class="ren-default-card">
                                <b-card-body class="px-1 py-1 d-flex align-items-center">
                                    <div class="d-flex justify-content-center align-items-center mr-2">
                                        <b-avatar size="6em" variant="light-warning">
                                            <RtIcon variant="thin" icon="coins" :size="37"/>
                                        </b-avatar>
                                    </div>
                                    <div>
                                        <h6>{{ i18nT(`Solde locataire`) }}</h6>
                                        <h1 
                                            class="m-0"
                                            :class="stats.TotalNetIncome  >= 0 ? 'text-primary' : 'text-danger'"
                                        >
                                            {{ {amount: stats.TotalNetIncome } | currency }}
                                        </h1>
                                    </div>
                                </b-card-body>
                            </b-card>
                        </b-col>
<!--                        <b-col-->
<!--                            xl="4"-->
<!--                            lg="6"-->
<!--                            cols="12"-->
<!--                        >   -->
<!--                            <b-card no-body border-variant='primary'>-->
<!--                                <b-card-body class="px-1 py-1">-->
<!--                                    <h6 class="font-small-2">{{ i18nT(`Revenu brut`) }} <br> {{ i18nT(`(année)`) }}</h6>-->
<!--                                    <h3 -->
<!--                                        class="m-0 text-primary text-nowrap"-->
<!--                                    >-->
<!--                                        {{ {amount: stats.TotalGrossIncome} | currency }}-->
<!--                                    </h3>-->
<!--                                </b-card-body>-->
<!--                            </b-card>-->
<!--                        </b-col>-->
<!--                        <b-col-->
<!--                            xl="4"-->
<!--                            lg="6"-->
<!--                            cols="12"-->
<!--                        >   -->
<!--                            <b-card no-body border-variant='danger'>-->
<!--                                <b-card-body class="px-1 py-1">-->
<!--                                    <h6 class="font-small-2">{{ i18nT(`Dépenses`)}} <br> {{ i18nT(`(année)`) }}</h6>-->
<!--                                    <h3 -->
<!--                                        class="m-0 text-danger text-nowrap"-->
<!--                                    >-->
<!--                                        {{ {amount: stats.TotalExpenses} | currency }}-->
<!--                                    </h3>-->
<!--                                </b-card-body>-->
<!--                            </b-card>-->
<!--                        </b-col>-->
<!--                        <b-col-->
<!--                            xl="4"-->
<!--                            lg="6"-->
<!--                            cols="12"-->
<!--                        >   -->
<!--                            <b-card no-body border-variant='secondary'>-->
<!--                                <b-card-body class="px-1 py-1">-->
<!--                                        <div class="d-flex align-items-center mb-1">-->
<!--                                            <h6 class="mr-50 font-small-2 m-0">{{ i18nT(`Rentabilité`) }}</h6>-->
<!--                                            <span-->
<!--                                                v-b-tooltip.hover="i18nT(`La rentabilité locative nette est calculé à la base de la formule suivante: (Loyer annuel – Dépenses) x 100 / Prix d’acquisition du bien`)">-->
<!--                                            <RtIcon variant="light" icon="circle-info"/>-->
<!--                                        </span>-->
<!--                                        </div>-->
<!--                                        <h3-->
<!--                                            v-b-tooltip.hover="currentBuilding.BuildingInitialPrice ? '' : i18nT(`Prix d'acquisition inconnu`)"-->
<!--                                            class="m-0 text-nowrap text-truncate"-->
<!--                                            :class="{ 'font-small-4 py-25': !currentBuilding.BuildingInitialPrice }"-->
<!--                                        >-->
<!--                                            {{ currentBuilding.BuildingInitialPrice ? stats.Profitability + '%' : i18nT(`Prix d'acquisition inconnu`) }}-->
<!--                                        </h3>-->
<!--                                </b-card-body>-->
<!--                            </b-card>-->
<!--                        </b-col>-->
<!--                        <b-col-->
<!--                            cols="12"-->
<!--                        >-->
<!--                            <b-card no-body :border-variant="stats.TotalNetIncome  >= 0 ? 'primary' : 'danger'">-->
<!--                                <b-card-body class="px-1 py-1 d-flex align-items-center">-->
<!--                                    <div class="d-flex justify-content-center align-items-center mr-2">-->
<!--                                        <b-avatar size="6em" variant="light-warning">-->
<!--                                            <RtIcon variant="thin" icon="coins" :size="37"/>-->
<!--                                        </b-avatar>-->
<!--                                    </div>-->
<!--                                    <div>-->
<!--                                        <h6>{{ i18nT(`Solde locataire`) }}</h6>-->
<!--                                        <h1 -->
<!--                                            class="m-0"-->
<!--                                            :class="stats.TotalNetIncome  >= 0 ? 'text-primary' : 'text-danger'"-->
<!--                                        >-->
<!--                                            {{ {amount: stats.TotalNetIncome } | currency }}-->
<!--                                        </h1>-->
<!--                                    </div>-->
<!--                                </b-card-body>-->
<!--                            </b-card>-->
<!--                        </b-col>-->
                        <b-col
                            cols="12"
                        >
                            <div class="mt-1 d-flex justify-content-around">
                                <b-button class="w-100 mr-1" variant="outline-primary">{{ i18nT(`Finances`)}}</b-button>
                                <b-button class="w-100" variant="outline-primary">{{ i18nT(`Détail du solde`)}}</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="screwdriver-wrench"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Interventions`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Interventions`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <div
                                v-for="(item,index) in maintenance"
                                :key="`maintenance` + index"
                                class="pt-1"
                            >
                                <maintenance-card :maintenance="item" />
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="clipboard-check"/>
                    </div>
                    <div class="d-flex">
                        <span class="pt-25">{{ i18nT(`Tâches`)}}</span>
                        <b-badge class="ml-50" variant="primary">2</b-badge>
                    </div>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Tâches en cours`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                            v-for="(task, index) in tasks"
                            :key="'task' + index"
                        >
                            <task-card :task="task" :isCompleted="taskCompleteBtnClicked" :selected-task="selectedTask" @complete="completeTask"/>

<!--                            <b-card no-body border-variant="primary">-->
<!--                                <b-card-body>-->
<!--                                    <div class="d-flex justify-content-between">-->
<!--                                        <div class="d-flex justify-content-between align-items-center">-->
<!--                                            <span-->
<!--                                                :class="{'text-primary': taskCompleteBtnClicked && selectedTask.TaskID === task.TaskID}"-->
<!--                                                @click="completeTask(task)"-->
<!--                                            >-->
<!--                                                <RtIcon-->
<!--                                                    :size="25"-->
<!--                                                    variant="solid"-->
<!--                                                    icon="check-circle mr-1 cursor-pointer ren-task-box"-->
<!--                                                />-->
<!--                                            </span>-->
<!--                                            <span class="mr-1 text-primary">{{task.TaskSubject}}</span>-->
<!--                                            <span>{{task.TaskDeadline | date}}</span>-->
<!--                                        </div>-->
<!--                                        <div>-->
<!--                                            <span v-if="task.TaskDeliveryType !== 'once'" class="badge badge-success">-->
<!--                                                {{ i18nT('RÉPÉTER')}}-->
<!--                                            </span>-->
<!--                                            <span v-else-if="task.TaskActive === 1 && compareDate(task.TaskDeadline)" class="badge badge-primary">-->
<!--                                                {{ i18nT(`ACTIVE`)}}-->
<!--                                            </span>-->
<!--                                            <span v-else-if="task.TaskActive === 1 && !compareDate(task.TaskDeadline)" class="badge badge-warning">-->
<!--                                                {{ i18nT(`EN RETARD`)}}-->
<!--                                            </span>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </b-card-body>-->
<!--                            </b-card>-->
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab
            :title="i18nT(`Notes`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="sticky-note"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Notes`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Notes`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                            v-for="(note, index) in notes"
                            :key="'note' + index"
                        >
                            <note-card :note="note" @delete="deleteNote(index)" />
<!--                            <b-card no-body border-variant="primary">-->
<!--                                <b-card-body :class="`note-bg-${note.NoteColour}`">-->
<!--                                    <b-row>-->
<!--                                        <b-col-->
<!--                                            cols="12"-->
<!--                                            md="1"-->
<!--                                            class="text-center"-->
<!--                                        >-->
<!--                                        <rt-icon icon="note-sticky" variant="thin" :size="40" />-->
<!--                                        </b-col>-->
<!--                                        <b-col-->
<!--                                            md="10"-->
<!--                                            cols="12"-->
<!--                                            class="pl-2"-->
<!--                                        >-->
<!--                                            <h5 class="cursor-pointer text-truncate w-75 mb-0">{{ note.NoteText }}</h5>-->
<!--                                            <small>{{ note.NoteCreateTime | date }}</small>-->
<!--                                        </b-col>-->
<!--                                        <b-col-->
<!--                                            cols="12"-->
<!--                                            md="1"-->
<!--                                            sm="12"-->
<!--                                        >-->
<!--                                            <b-button-->
<!--                                                variant="warning"-->
<!--                                                class="pill float-right ml-1"-->
<!--                                                @click="deleteNote(index)"-->
<!--                                            >-->
<!--                                                <rt-icon icon="trash text-danger" variant="regular"/>-->
<!--                                            </b-button>-->
<!--                                        </b-col>-->
<!--                                    </b-row>-->
<!--                                </b-card-body>-->
<!--                            </b-card>-->
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                :label="i18nT(`Nouvelle note`)"
                            >
                                <b-form-textarea
                                    :id='i18nT(`Nouvelle note`)'
                                    v-model="newNote"   
                                    :rows="4"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-button 
                                class="float-right mb-1" 
                                :disabled="newNote.length <= 0" 
                                variant="outline-primary"
                                @click="saveNewNote"
                            >
                                {{ i18nT(`Sauvegarder`) }}
                            </b-button>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab
            :title="i18nT(`Documents`)"
            v-if="documentsCount > 0"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="briefcase"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Documents`)}}
                        <b-badge variant="primary">{{documentsCount}}</b-badge>
                    </p>
                </div>
            </template>
            <document-tab @countChanged="updateDocumentCount"/>
            <b-card
                no-body
                v-if="false"
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Documents`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                        </b-col>
                        <b-col
                            cols="12"
                        >
                            <div
                                v-for="(item,index) in documents"
                                :key="`document` + index"
                                class="pb-1 pt-1"
                            >
                                <document-card :document="item" />
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <b-tab
            :title="i18nT(`Activité`)"
        >
            <template #title>
                <div class="d-flex align-items-center ren-title">
                    <div class="mr-50">
                        <RtIcon :size="18" variant="light" icon="clock-rotate-left"/>
                    </div>
                    <p class="m-0">
                        {{ i18nT(`Activité`)}}
                    </p>
                </div>
            </template>
            <b-card
                no-body
            >
                <b-card-header class="pb-0">
                    <h4>{{ i18nT(`Historique`) }}</h4>
                </b-card-header>
                <b-card-body>
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <hr class="mt-0 mb-2"/>
                            <app-timeline>
                                <app-timeline-item
                                    v-for="(log, index) in logs"
                                    :key="index + 'log'"
                                    :title="log.ActionText"
                                    :subtitle="'Email: ' + log.LogUserEmail"
                                    :icon="getTimelineIcon(log.LogAction)"
                                    :variant="getTimelineVariant(log.LogAction)"
                                    :time="formatDate(log.CreatedAt)"
                                    :showDelete="false"
                                />
                            </app-timeline>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-tab>
        <div class="ps__rail-y">
            <div class="ps__thumb-y"></div>
        </div>
    </b-tabs>
</template>




<script>
import {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BForm,
    BFormRating,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BModal,
    BProgress,
    VBTooltip,
} from 'bootstrap-vue'
import router from '@/router'
import { dictToSelectArray } from '@core/utils/utils'
import VueApexCharts from 'vue-apexcharts'
import ContactCard from "@/views/components/contactCard/ContactCard.vue";
import MaintenanceCard from '@/views/components/maintenanceCard/MaintenanceCard.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import useAuth from '@/auth/useAuth'
import moment from 'moment';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@/views/components/activityTimelineItem/ActivityTimelineItem.vue'
import TaskCard from "@/views/components/taskCard/TaskCard.vue";
import NoteCard from "@/views/components/noteCard/NoteCard.vue";
import vSelect from 'vue-select'
import DocumentTab from '@/views/components/tabs/DocumentTab/DocumentTab.vue'


export default{
    components:{
        NoteCard,
        TaskCard,
        DocumentCard,
        ContactCard,
        BAvatar,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BFormTextarea,
        BTabs,
        BTab,
        BDropdown,
        BDropdownItem,
        BBadge,
        BLink,
        BForm,
        BFormRating,
        BFormInput,
        BFormCheckbox,
        BModal,
        BProgress,
        MaintenanceCard,
        AppTimeline,
        AppTimelineItem,
        VueApexCharts,
        vSelect,
        DocumentTab
    },
    directives:{
        'b-tooltip': VBTooltip
    },
    data(){
        return {
            stats: {},
            years: [],
            year: new Date().getFullYear(),
            maintenance: [],
            tasks: [],
            taskCompleteBtnClicked: false,
            notes: [],
            newNote: '',
            documentsCount: 0,
            logs: [],
            currentBuildingId: router.currentRoute.params.id,
            currentBuilding: {},
            selectedTask: {},
            pieChartOptions: 
            {
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.9,
                        opacityTo: 1,
                        stops: [75, 95],
                    }
                },
            },
            pieSeries: [],
            areaChartOptions: {
                colors: ['#ea5455', '#72a333'],
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient'
                },
            },
            areaSeries: [],
        }
    },
    props: {
        buildingData: {
            type: Object,
            required: true
        }
    },
    created() {
        this.getStats(true)
        
    },
    methods: {
        getStats(showLoader = false) {
            
            this.$http.get(`landlord/buildings/stats?building_id=${this.currentBuildingId}&year=${this.year}`, {showLoader: showLoader === true})
            .then(({data}) => {
                console.log('Stats: ', data.data)
                this.stats = data.data.summary

                const self = this
                const tRevenue = this.i18nT(`Revenu`)

                this.pieChartOptions = {
                    labels: data.data.properties_income.data.map(income => income.name),
                    colors: data.data.properties_income.colors.splice(0, data.data.properties_income.data.length),
                    legend: {
                        position: 'bottom'
                    },
                    theme: {
                        mode: this.isDarkMode ? 'dark' : 'light'
                    },
                    stroke: {
                        colors: [this.isDarkMode ? '#343a40' : '#fff'] 
                    },
                    tooltip: {
                        custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return (
                                `
                                <p class="mt-50 mb-0 mx-1">
                                    ${w.globals.labels[seriesIndex]}
                                </p>
                                <p class="mx-1 mb-50">
                                    ${tRevenue}: ${self.$options.filters.currency({amount: data.data.properties_income.data[seriesIndex].value})} (${data.data.properties_income.data[seriesIndex].percent}%)
                                </p>
                                `
                            );
                        }
                    }
                }
                this.pieSeries = data.data.properties_income.data.map(income => +income.value).filter(value => value > 0)
                if(this.pieSeries.length === 0) {
                    this.pieChartOptions.noData = {
                        text: 'No data available',
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '14px',
                            fontFamily: undefined
                        }
                    }
                    this.pieChartOptions.labels = [];
                }
                // this.pieSeries = [10, 10, 15, 20, 0, 2, 8, 0, 0, 35]

                this.areaChartOptions = {
                    xaxis: {
                        categories: data.data.income_expense.month_labels
                    },
                    theme: {
                        mode: this.isDarkMode ? 'dark' : 'light'
                    },
                }
                this.areaSeries = [
                    {
                        name: this.i18nT(`Dépense`),
                        data: data.data.income_expense.expenses_data
                        // data: [45, 52, 38, 45, 19, 33],

                    },
                    {
                        name: this.i18nT(`Revenu`),
                        data: data.data.income_expense.incomes_data
                        // data: [18, 1, 100, 50, 50, 50]
                    }
                ]
            })
        },
        updateDocumentCount(newCount) {
            this.documentsCount = newCount
        },
        completeTask(task) {

            // TODO Handle complete task when api is ready!
            this.taskCompleteBtnClicked = !this.taskCompleteBtnClicked

            this.selectedTask = task

            setTimeout(() => {
                if (!this.taskCompleteBtnClicked) return
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Status changed`,
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.taskCompleteBtnClicked = false
            }, 2000)
        },
        compareDate(date) {

            const currentDate = moment().startOf('day')
            const inputDate = moment(date)

            if(inputDate.isBefore(currentDate)) {
                return false
            } 
            else if (inputDate.isAfter(currentDate)) {
                return true
            }
            else {
                return true
            }
        },
        getTimelineIcon(action) {
            if (action === 'insert') {
			    return 'plus'
            }
            else if (action === 'update') {
			    return 'arrow-up-from-bracket'
            }
            else if (action === 'execArchive') {
			    return 'box-archive'
            }
            else if (action === 'execUnarchive') {
			    return 'box-archive'
            }
            else if (action === 'deleteRestore') {
                return 'arrows-rotate'
            }
            else if (action === 'deleteTrash') {
			    return 'trash'
            }
        },    
        getTimelineVariant(action) {
            if(action === 'insert' || action === 'update') {
                return 'primary'
            } else if (action === 'execUnarchive' || action === 'deleteRestore') {
                return 'info'
            }
            else if (action === 'execArchive') {
                return 'warning'
            }
            else {
                return 'danger'
            }
        },
        formatDate(date) {
            let dateFormat = useAuth.getDateFormat() !== (null || 'null') ? useAuth.getDateFormat() : 'DD/MM/YYYY';
            dateFormat += ' HH:mm:ss'
            const formatted = moment(new Date(date)).format(dateFormat);
            
            return formatted;
        },
        deleteNote(index) {
            // TODO add call request when api is ready or push to parent comp

            this.notes.splice(index, 1)
        },
        saveNewNote() {
            // TODO add call request when api is ready or push to parent comp

            this.notes.push({
                NoteText: this.newNote,
                NoteCreateTime: new Date(),
                NoteColour: 'yellow',
            })
            this.newNote = ''
        },
    },
    watch: {
        buildingData: {
            handler: function(val) {
                if(val) {
                    this.currentBuilding = val.building
                    this.years =  val.years ? val.years.map(year => ({value: year, text: `${this.i18nT(`Année`)} ${year}`})) : [{value: 2024, text: `${this.i18nT(`Année`)} 2024`}]
                    this.maintenance = val.maintenance
                    this.tasks = val.tasks
                    this.notes = val.notes
                    this.documentsCount = val.documents
                    this.logs = val.logs
                }
            },
            deep: true
        },
        isDarkMode(val) {
            this.$refs.pieChart.updateOptions({
                theme: {
                    mode: val ? 'dark' : 'light'
                },
                stroke: {
                    colors: [val ? '#343a40' : '#fff'] 
                }
            })
            
            this.$refs.areaChart.updateOptions({
                theme: {
                    mode: val ? 'dark' : 'light'
                }
            })
        }
    },
    computed: {
        isDarkMode(){
            return this.$store.state.appConfig.layout.skin === 'dark'
        }
    }
}
</script>


<style lang="scss">

.rt-tabs-list{
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 8px;
}

.rt-tabs-list .nav-link.active {
    box-shadow: none;
}

.rt-tabs-list::-webkit-scrollbar {
    width: 10px;
    height: 7px;
}

.rt-tabs-list:hover::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 20px;
}

// .rt-tabs-list:hover::-webkit-scrollbar-track {
//     background: #eee;
//     border-radius: 20px;
// }

.dark-layout .rt-tabs-list:hover::-webkit-scrollbar-thumb {
    background: #b4b7bd;
}

.dark-layout .rt-tabs-list:hover::-webkit-scrollbar-track {
    background: #6e6b7b;
}



</style>