<template>
    <div v-if="buildingDetails">
        <ellipsis-dropdown
            componentName="edit-building"
            :showDeleteBtn="curBuildingId !== undefined"
            @save="onEdit"
            @delete="onDelete"
            :itemId="curBuildingId"
        />
        <div class="d-inline-flex justify-content-start align-items-center mb-1 ml-50">
            <span @click="$router.push({name:'buildings'})" class="cursor-pointer d-flex align-items-center">
                <RtIcon icon="chevron-left" variant="light" :size="20" />
            </span>
        <h3 class="ml-1 mb-0">{{buildingDetails.BuildingAddress}}</h3>
        </div>
        <b-row>
            <b-col
                cols="12"
                md="6"
            >
                <b-card
                    class="section-container"
                    no-body
                >
                    <b-card-header>
                        <h4 class="card-title">{{ i18nT(`Informations`) }}</h4>
                    </b-card-header>

                    <b-card-body>
                        <b-row>
                            <b-col cols="12">
                              <map-carousel :images="buildingImages" :address="buildingAddress" @modal-photos="openModal"/>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col cols="6">
                                <h4  class="mt-1">{{ i18nT(`Adresse`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="buildingDetails.BuildingActualAddress"
                                        class="ren-info-cards"
                                    >
                                        <div class="d-flex flex-column">
                                            <p class="m-0">{{ buildingDetails.BuildingActualAddress }}
                                            {{ buildingDetails.BuildingActualAddress2 }}</p>
                                            <p class="m-0">{{ buildingDetails.BuildingActualZip }} {{ buildingDetails.BuildingActualCity }}</p>
                                            <p class="m-0">{{ buildingDetails.BuildingActualCountry }}</p>
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4  class="mt-1">{{ i18nT(`Description`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="buildingDetails.BuildingComments"
                                        class="ren-info-cards"
                                    >
                                        <p class="m-0 mb-1">{{ buildingDetails.BuildingComments }}</p>
                                        <p class="m-0"><b>{{i18nT(`Surface`)}}</b>  {{ buildingDetails.BuildingSize}}</p>
                                        <p class="m-0"><b>{{i18nT(`Année de construction `)}}</b>  {{buildingDetails.BuildingConstructionYear }}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <h4 class="mw-75">{{ i18nT(`Equipements`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="buildingExtras"
                                        class="ren-info-cards"
                                    >
                                        <div v-for="(extra,index) in buildingExtras" :key="'extra'+index">
                                            <span class="mr-50">
                                                <RtIcon icon="check" variant="light text-primary"/>
                                            </span>
                                            {{i18nT(extra.label)}}
                                        </div>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4 class="mw-75">{{ i18nT(`Biens`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col
                                        cols="12"
                                        v-if="buildingProperties"
                                        class="ren-info-cards d-flex flex-column"
                                    >
                                        <b-link  v-for="(property, index) in buildingProperties" :key="'buildingProperty'+index" :to="{name:'properties'}">
                                            {{i18nT(property.label)}}
                                        </b-link>

                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas de Biens`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col
                cols="12" md="6"
            >
                <building-details-tabs :building-data="tabsData"/>
            </b-col>
        </b-row>
        <b-modal
            id="modal-map-carousel-full"
            hide-footer
            size="xl"
            content-class="modal-fullscreen"
        >
            <map-carousel :images="buildingImages" :address="buildingAddress" :full="true"/>
        </b-modal>
    </div>
</template>

<script>
import {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BForm,
    BFormRating,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BModal,
    BProgress,
    VBTooltip,
    BCarousel,
    BCarouselSlide,
} from 'bootstrap-vue'
import router from '@/router'
import { dictToSelectArray } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from '@/auth/useAuth'
import moment from 'moment';
import BuildingDetailsTabs from './BuildingDetailTabs.vue'
import EllipsisDropdown from "@core/components/EllipsisDropdown.vue";
import Ripple from 'vue-ripple-directive'
import MapCarousel from "@/views/components/mapCarousel/MapCarousel.vue";



export default {
    components: {
        MapCarousel,
        EllipsisDropdown,
        BAvatar,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BFormTextarea,
        BTabs,
        BTab,
        BDropdown,
        BDropdownItem,
        BBadge,
        BLink,
        BForm,
        BFormRating,
        BFormSelect,
        BFormInput,
        BFormCheckbox,
        BModal,
        BProgress,
        BuildingDetailsTabs,
        BCarousel,
        BCarouselSlide,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            buildingDetails: {},
            buildingExtras: [],
            buildingImages: [],
            buildingProperties: [],
            activationLink: '',
            curBuildingId: router.currentRoute.params.id,
            tabsData: {},
        }
    },
    created() {
        this.$http.get(`landlord/buildings/details?id=${router.currentRoute.params.id}`)
            .then(({data}) => {
                this.tabsData = data.data;
                this.buildingDetails = data.data.building;
                this.buildingExtras = data.data.extras || [];
                this.buildingImages = data.data.images || [];
                this.buildingProperties = data.data.properties || [];
            });
    },
    computed:{
        buildingAddress(){
            return (this.buildingDetails.BuildingActualAddress || '') + ' ' + (this.buildingDetails.BuildingActualZip || '') + ' ' + (this.buildingDetails.BuildingActualCity || '') + ' ' + (this.buildingDetails.BuildingActualCountry || '');
        }
    },
    methods: {
        openModal(photo){
            this.selectedPhoto = photo;
            this.$bvModal.show('modal-map-carousel-full')
        },
        onDelete() {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Supprimer définitivement l'immeuble?`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if(result.value) {
                    this.$http.delete(`landlord/buildings?id=${this.curBuildingId}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès`),
                                text: this.i18nT(`Supprimé avec succès`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.$router.push({ name:'buildings'})
                        })
                        .catch((err) => {
                            this.$swal({
                                icon: 'error',
                                title: 'Error!',
                                text: this.errorMessage(err),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.$router.push({ name:'buildings'})
                        })
                }
            })
        },
        onEdit(){
            this.$router.push({ name: 'edit-building', params: { id: this.curBuildingId } })
        }
    }
}

</script>

<style>

.ren-info-cards {
    min-height: 120px;
}

.ren-task-box:hover {
    transform: translateY(-2.5px);
    transition: transform 0.3s ease-in-out;
}

.ren-title {
    transition: transform 0.3s ease-in-out;
    opacity: .7;
}

.ren-title:hover {
    opacity: 1;
    transform: translateY(-2.5px);
}

.nav-item > a[aria-selected="true"] .ren-title {
    opacity: 1;
}
.modal-fullscreen{
    max-height: 90vh;
}



</style>